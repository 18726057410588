.main-container {
  max-width: 600px;
}

.header {
  background: #4a4a4a;
  color: #eee;
  height: calc(2.5rem + 3px);
  line-height: 2.5rem;
  border-bottom: 3px solid #ccc;
  display: flex;
}

.header-section {
  flex-grow: 1;
  flex-basis: 0;
}

.header-left {
  display: flex;
}

.header-right {
  display: flex;
  justify-content: flex-end;
}

.header-title {
  color: #fff;
  padding-left: 1rem;
}

.header-title:hover {
  color: #eee;
}

.header-user-photo {
  border-radius: 50%;
  height: 2rem;
  margin: 0.25rem;
}

.candidate-control {
  margin-bottom: 5px;
}

.candidate-control:last-of-type {
  margin-bottom: 0;
}

.clickable-icon {
  pointer-events: auto !important;
  cursor: pointer;
}

.ballot-candidate {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.ballot-candidate:not(:last-child) {
  margin-bottom: 1rem;
}
